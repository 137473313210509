.SelectClient__goToClientBtn {
  background: transparent;
  width: fit-content;
  color: #165397;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.SelectClient__title_text {
  font-weight: 500;
}

.SelectClient {
  width: 100%;
}

.SelectClient__tag {
  width: fit-content;
  display: block;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  width: 100%;
  margin: 0;
  min-height: 24px;
}
