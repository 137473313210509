$blue-color: #0e53a7;
$dark-blue-color: #08458f;
$light-blue-color: #668fbd;
$white-color: #fff;
$black-color: #000;
$semi-black-color: #2f2f2f;
$orange-color: #ff8900;
$dark-orange-color: #b76200;
$grey-color: #e6e6e6;
$dark-grey-color: #505050;
$light-grey-color: #efefef;

$profile-settings-border-color: #c0c0c0;
$profile-settings-color: #f8f8f8;

$control-card-border-color: #c6c6c6;
$control-card-gradient-start-color: #6fb0ff;
$control-card-gradient-end-color: #f1f7ff;

$login-reset-color: #4096ff;
$login-type-color: #113157;
$login-border-color: #8d8484;

$column-list-color: #eaeaea;

$scrollbar-color: #d9d9d9;
$scroll-arrow-color: #8f959d;

$card-group-color: #a5a5a5;
$deal-search-color: #023674;
$deal-count-color: #9da6b2;

$date-color: #acb7c5;

$header-color: #4073ac;
$header-button-color: #acb6c5;

$status-date-color: #818080;
$gradient-status-color: linear-gradient(0deg, rgba(200, 225, 255, 1) 0%, rgba(241, 247, 255, 1) 35%, rgba(255, 255, 255, 1) 100%);

$warn-color: #f00;

$pagination-active-color: #4200ff;
$pagination-disabled-color: #919eab;
$pagination-border-color: #dfe3e8;

$link-open-program-color: #5b5a5a;

$blanks_text_color: #8d959f;

$tabs-text-color: #063899;
$tabs-text-active: #568fff;

$price-color: #696969;

$table_header_color: #daebff;
$table_row_highlight: #edf5ff;
$public_gallery_bck: #f2f2f2;

$hide_btn: #ced1d4;
