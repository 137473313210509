@import "../../../../../styles/variables.scss";

.clientCard {
  &__modal_header {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-color;
    border-radius: 5px 5px 0 0;
  }

  &__modal_close {
    padding: 0;
    border: none;
  }
  &__wrapper {
    padding: 20px;
  }
  &__header_btns {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
  }
  &__header_to_list {
    background-color: $orange-color;
  }

  &__hystory_block {
    display: flex;
    align-items: center;
    span {
      margin: 0 0 0 4px;
    }
  }
  &__inputs {
    max-width: 80%;
  }

  &__input_block {
    margin: 0 0 10px 0;
    span {
      font-weight: 600;
    }
  }

  &__inputs_btns_block {
    margin: 20px 0 0 0;
    display: flex;
  }

  &__inputs_btn_cancel {
    margin: 0 15px 0 0;
  }

  &__input_phone {
    border-width: 1px;
    border-style: solid;
    border-color: $scrollbar-color;
    padding: 7px 11px;
    border-radius: 6px;
  }

  &__input_phone_error {
    border-color: $warn-color;
  }

  &__error_hint {
    color: #fd0404;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
