@import "../../../../../styles/index.scss";

.projects {
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow-y: auto;
  padding-right: 10px;

  &_not_found {
    margin: 4px 0 20px;
  }
}

.collapse {
  &__header {
    display: flex;
    align-items: center;
    column-gap: 12px;

    &_project {
      color: $black-color;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      column-gap: 7px;

      &_user_name {
        color: $black-color;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
      }
    }

    &_date,
    &_name {
      color: $status-date-color;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__expand {
    transform: rotate(180deg);
    transition: 0.3s all;
    &_rotate {
      transform: rotate(0);
      transition: 0.3s all;
    }
  }

  &__box {
    display: flex;
    column-gap: 16px;

    &_preview {
      &_image {
        width: 120px;
        height: 120px;
      }
    }

    &_data {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 12.5px;

      &_title {
        color: $black-color;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
      }

      &_button_block {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        &_row {
          display: flex;
          justify-content: space-between;
        }

        &_custom_button {
          width: max-content;
          background: $grey-color;
          color: $black-color;
        }
      }

      &_footer {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        &_history {
          display: flex;
          align-items: baseline;
          column-gap: 5px;
          padding: 5px 0;

          &_date {
            color: $status-date-color;
            font-size: 10px;
            font-weight: 400;
            line-height: normal;
          }

          &_custom_link {
            width: max-content;
            background-color: transparent;
            color: $link-open-program-color;
            font-size: 10px;
            font-weight: 400;
            line-height: normal;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.collapse__box_data_footer_history_custom_link.collapse__box_data_footer_history_custom_link {
  text-decoration: underline;
}
