.SendPanoModal {
  padding: 5px 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sendBtn {
  margin-left: auto;
}
