@import "../../../styles/index.scss";

.gallery {
  &_cards_container {
    display: block;
    width: 100%;
    height: 100%;
    overflow: auto;

    &_cards {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      overflow-y: auto;
      padding: 6px;
    }

    &__no_data {
      display: flex;
      justify-content: center;
      width: 100%;
      min-height: 80px;
    }
    &_warning {
      padding: 20px;
      z-index: 10;
      top: 0;
      left: 0;
      position: absolute;
      background-color: $white-color;
      border-radius: 5px;
    }
    &_warning_btns {
      margin: 10px 0;
      button {
        margin: 0 0 10px 0;
      }
    }
    &__project_input {
      margin: 0 0 20px 0;
    }

    &__buttons_block {
      display: flex;
      align-items: center;
      gap: 10px;
      width: fit-content;
      height: fit-content;
      margin: 0 0 20px 0;
    }

    &__block {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
    }

    &__modal_header {
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $grey-color;
      border-radius: 5px 5px 0 0;
    }

    &__modal_close {
      padding: 0;
      border: none;
    }

    &__wrapper_modal {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__wrapper_modal_btn {
      margin: 0 0 20px 0;
      background-color: $semi-black-color;
      &:last-child {
        margin: 0;
      }
    }

    &__modal_copy_body {
      padding: 20px;
    }
    &__project_input_btn {
      display: flex;
      justify-content: center;
    }
    &__project_input_btn_copy {
      background-color: $semi-black-color;
    }
  }

  &_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom_button {
  width: 100%;
}
