@import "../../../styles/index.scss";

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  color: $black-color;
}

.custom_select_orange.custom_select_orange {
  width: 140px !important;
  max-width: 140px !important;
  color: $black-color;
  border-radius: 6px;
  &:hover {
    background-color: $white-color;
  }
}

.ant-select .ant-select-arrow {
  color: $black-color;
}

.ant-select-selection-placeholder {
  font-size: 12px;
}
