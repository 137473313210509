.AddEmailModal {
  display: flex;
  flex-direction: column;
  gap: 7px;
  &__select {
    &_block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 7px;
    }
  }

  &__footer {
    &_btns {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 15px;
    }
  }

  &__add {
    &_btn {
      border: none;
      padding: 0;
      position: absolute;
      right: 0;
      top: -5px;
    }
  }
  &__errortext {
    color: #ff0000;
  }

  &__faq {
    font-size: 12px;
    &_link {
      color: #0000ee;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.list_item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style-type: decimal;
  margin-bottom: 15px;
}

.list_item_img {
  width: 350px;
  margin: 0 auto;
  border: 0.5px solid #d9d9d9;
  border-radius: 5px;
}

.list {
  list-style-type: decimal;
  height: 800px;
  overflow-y: scroll;
}

.EmailFaq__other_text {
  font-weight: 500;
  font-size: 14px;
}
