@import "../../styles/index.scss";

.custom_styles {
  .ant-checkbox + span {
    padding-inline-start: 12px;
    padding-inline-end: 12px;
    color: $black-color;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;

    &:after {
      width: 6.714286px;
      height: 12.142857px;
    }
  }

  .ant-checkbox-wrapper {
    max-width: calc(50% - 10px);
    width: 100%;
  }
}

.custom_styles {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
