.workstationsList {
  padding: 30px 0;
  min-width: 780px;
}

@import "../../../../styles/variables.scss";

.workstation {
  padding: 30px 0;
  min-width: 762px;
  &__card {
    display: flex;
    padding: 12px 10px;
    align-items: center;
    margin: 0 0 10px 0;
    max-height: 50px;
    border: 1px solid $date-color;
    border-radius: 5px;
  }
  &__index {
    margin: 0 20px 0 0;
    max-width: 40px;
  }
  &__block {
    width: 30%;
    margin: 0 10px 0 0;
    font-weight: 600;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__btns {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
  &_btn {
    padding: 0;
  }
  &__edit {
    margin: 0 10px;
    cursor: pointer;
  }
}

.workstation__btn {
  padding: 0;
  border: none;
}

.workstation__warning_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.workstation__waringn_btn {
  width: 125px;
}
