@import "../../styles/index.scss";

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $orange-color;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: $orange-color;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: $orange-color;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $orange-color;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: $dark-orange-color;
}

.ant-radio-button-wrapper:hover {
  color: $dark-orange-color;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border-color: $dark-orange-color;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: $dark-orange-color;
}

.ant-input:-webkit-autofill {
  -webkit-background-clip: text;
}

.ant-radio-button-wrapper:hover {
  color: #000;
}

.ant-radio-button-wrapper:active {
  color: #ff8900;
}
