.notification-title-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-title-name {
  color: #4b7cb0;
  font-size: 15px;
  font-weight: 500;
}

.notification-title-time {
  color: #3f3f3f;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.24px;
}

.notification-text-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
}

.notification-text {
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
}

.notification-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.notification-btn {
  width: calc(50% - 10px);
  padding: 4px 7px;

  & span {
    font-size: 13px;
  }
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
  padding: 12px;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close {
  top: 15px;
  inset-inline-end: 6px;
}

.dropdown-btn.dropdown-btn.dropdown-btn {
  border-color: transparent;
  padding: 0;
  border: none !important;

  & span {
    width: 100%;
    text-align: start;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}
