.QrModal {
  &__footer {
    &_btns {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 15px;
      margin-top: 10px;
    }
  }

  &__list {
    padding: 0 15px;
    margin-bottom: 5px;
  }

  &__error {
    font-size: 14px;
    color: #ff0000;
  }
  &__success {
    font-size: 14px;
    color: green;
  }
}
