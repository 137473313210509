@import "../../../../../../styles/index.scss";

.collapse {
  &__modal_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__box {
    display: flex;
    column-gap: 16px;

    &_preview {
      &_image {
        height: 120px;
        width: 120px;
      }
    }

    &_data {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 12.5px;

      &_title {
        color: $black-color;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
      }

      &_button_block {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        &_row {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
        }

        &_custom_button {
          width: max-content;
          background: $grey-color;
          color: $black-color;
        }
      }

      &_footer {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        &_history {
          display: flex;
          align-items: baseline;
          column-gap: 5px;
          padding: 5px 0;

          &_date {
            color: $status-date-color;
            font-size: 10px;
            font-weight: 400;
            line-height: normal;
          }

          &_custom_link {
            width: max-content;
            background-color: transparent;
            color: $link-open-program-color;
            font-size: 10px;
            font-weight: 400;
            line-height: normal;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.collapse__box_data_footer_history_custom_link.collapse__box_data_footer_history_custom_link {
  text-decoration: underline;
}
