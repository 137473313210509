@import "../../styles/index.scss";

.loginPage {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 450px;
    height: 100%;
  }

  &__title {
    margin-bottom: 15px;
    color: $black-color;
    font-size: 18px;
    font-weight: 400;
    color: #4073ac;
  }

  &__form {
    width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    align-items: center;

    &_inputs {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 15px 30px;
      width: 100%;
      background: #f5f5f5;
      box-shadow: 0px 4px 10px -5px rgba(0, 0, 0, 0.25);
    }

    &_footer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 191px;
    }

    &_phone {
      padding: 7px 8px;
      border-radius: 5px;
      border: 1px solid $scrollbar-color;
      background: $white-color;

      &:hover {
        border-color: $login-reset-color;
        border-inline-end-width: 1px;
      }

      &:focus {
        border-color: $login-reset-color;
        border-inline-end-width: 1px;
      }
    }

    &_submit {
      max-width: 191px;
    }

    &_passwordReset {
      max-width: 191px;
      background-color: transparent;
      color: #4073ac;
    }
  }

  &__typeBtn {
    color: $login-type-color;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;

    &:hover {
      background: #f6f4f4;
      color: #000000;
    }
  }

  &__recent_wrapper {
    display: flex;
  }

  &__recent_header {
    margin: 0 0 10px 0;
    text-align: center;
    font-size: 16px;
    color: $light-blue-color;
    font-weight: 400;
  }

  &__recent_block {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 20px;
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.3s ease-in-out;
    }
  }

  &__recent_avatar {
    margin: 0 12px 0 0;
  }

  &__recent_name {
    display: inline-block;
    font-size: 12px;
    margin: 0 0 2px 0;
    font-weight: 600;
  }

  &__recent_email {
    font-size: 12px;
    color: $date-color;
  }

  &__passwordHint {
    color: black;
    font-weight: 600;
    font-size: 10px;
    display: inline-block;
    margin: -10px 0 0 0;
  }
}
