.ConnectionIdModal {
  &_title {
    display: inline-flex;
    font-size: 14px;
    font-weight: 450;
    color: #333;
    margin-bottom: 10px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    max-height: 150px;
    overflow-y: scroll;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__footer {
    &_btns {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 15px;
    }
  }
}
