@import "../../styles/index.scss";

.user {
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-shrink: 0;
  flex-grow: 1;

  @media screen and (max-width: 1440px) {
    column-gap: 8px;
  }

  &__custom_button {
    font-size: 12px;
    color: $white-color;
    border: 1px solid $white-color;
    background: $orange-color;
    padding: 8px 7px;

    &_name {
      border: none;
      padding: 0;
      &:hover {
        color: $white-color;
      }
    }
  }

  &__name {
    width: fit-content;
    color: $white-color;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    line-height: 16px;
    max-width: 210px;
    font-weight: 600;
    font-family: Roboto, serif;

    @media screen and (max-width: 1440px) {
      font-size: 14px;
    }
  }
}
