@import "../../../../styles/index.scss";

.dealsContainer {
  width: 284px;
  padding: 14px 8px 14px 10px;
  background: $column-list-color;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  min-height: 100%;

  @media screen and (max-width: 1440px) {
    padding: 10px 8px 10px 10px;
  }

  &__column {
    display: block;
    min-width: 230px;
    height: 100%;
    overflow: auto;
  }

  &__card {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    height: fit-content;
    padding-right: 10px;
  }

  &__show {
    margin: 0 8px 0 0;
  }
}
