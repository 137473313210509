@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
button {
  font-family: Roboto;
}

button,
input {
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
}

a,
a:link,
a:visited,
a:hover {
  text-decoration: none;
}

ul {
  list-style: none;
}
