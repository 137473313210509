@import "../../../styles/index.scss";

.profile_settings_user_card {
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 12px;
  transition: background-color 0.3s ease-in-out;
  padding: 5px 0;
  border-radius: 5px;

  &__data {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    font-size: 12px;
    &_email {
      color: $date-color;
    }
  }

  &:hover {
    background-color: $profile-settings-color;
    transition: background-color 0.3s ease-in-out;
  }
}
