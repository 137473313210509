@import "../../../styles/index.scss";

.gallery {
  &__block {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 10px;
  }

  &__img {
    &_block {
      object-fit: cover;
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        max-width: 365px;
        max-height: 365px;
      }
    }
  }

  &__title {
    width: fit-content;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc((100% - 90px) / 4);
    min-width: 300px;
    border-radius: 5px;
    background: $white-color;
    box-shadow: 0px -1px 10px 2px rgba(0, 0, 0, 0.087);

    &_published {
      color: #505050;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &_controle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 5px;
      max-width: 100%;
      overflow: hidden;
    }

    &_hide {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_hide_btn {
      padding: 0;
      border: none;
    }

    @media screen and (max-width: 1550px) {
      min-width: 220px;
    }

    @media screen and (max-width: 1024px) {
      width: 80%;
      min-width: 80%;
      margin: 0 auto;
    }

    @media screen and (max-width: 460px) {
      width: 90%;
      max-width: 370px;
      min-width: auto;
    }
  }

  &__card_title {
    padding: 10px;
    padding-left: 0;
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    color: $black-color;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 10;
  }

  &__author {
    color: #3f3f3f;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
  }
}
