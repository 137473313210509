@import "../../../styles/index.scss";

.custom_styles {
  .ant-skeleton.ant-skeleton-element .ant-skeleton-image {
    width: 100%;
    height: 250px;
  }

  .ant-skeleton.ant-skeleton-element {
    width: 100%;
  }
}
