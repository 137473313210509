@import "../../../../../styles/variables.scss";

.dealCardReminder {
  &__modal_header {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-color;
    border-radius: 5px 5px 0 0;
  }

  &__modal_close {
    padding: 0;
    border: none;
  }

  &__wrapper {
    padding: 10px;
  }

  &__time {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid $date-color;
    p {
      margin: 0 10px 0 0;
    }
  }

  &__comment {
    padding: 10px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid $date-color;
    p {
      margin: 0 0 10px 0;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    input {
      min-height: 50px;
    }
  }

  &__button {
    margin: 10px 0;
    background-color: $light-blue-color;
  }

  &__reminder_list_item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $date-color;
    }
  }

  &__reminder_list {
    max-height: 220px;
    overflow-y: scroll;
  }

  &__reminder_list_noscroll {
    overflow-y: auto;
    overflow: auto;
  }

  &__reminder_list_hint {
    margin: 0 0 0 20px;
    padding: 0;
    border: none;
  }

  &__reminder_list_empty {
    text-align: center;
    font-weight: 600;
  }

  &__reminder_list_error {
    color: $warn-color;
    text-align: center;
    font-weight: 600;
  }
}
