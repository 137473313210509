@import "../../../styles/index.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  color: $white-color;
  &:hover {
    background-color: $white-color;
  }
}

.custom_blue {
  background-color: #668fbd;
  &:hover {
    background-color: #5d83ad;
  }
}

.custom_orange {
  background-color: $orange-color;
}

.custom_gray {
  background-color: $grey-color;
  color: $dark-grey-color;
}

.custom_darkGrey {
  color: #fff;
  background-color: #505050;
}
