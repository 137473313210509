@import "../../../styles/variables.scss";

.publicGallery {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  gap: 10px;

  @media screen and (max-width: 1000px) {
    padding-top: 20px;
    justify-content: flex-start;
    gap: unset;
  }

  &__block {
    width: 60%;
    max-width: 1750px;
    border-radius: 10px;

    @media screen and (max-width: 1000px) {
      width: 95%;
    }
  }
}

.header {
  padding: 10px 30px;
  height: 70px;
  background-color: #f2f2f2;
  width: 100%;
  box-shadow: 0px 4px 9px 0px #00000026;
}
