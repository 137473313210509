@import "../../../styles/variables.scss";

.stuff {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  overflow-x: auto;
  width: 100%;
  max-width: 1200px;

  @media screen and (max-width: 1440px) {
    margin: 0;
    padding: 0;
    width: auto;
    min-width: 900px;
  }

  &__header_wrapper {
    margin: 10px 0 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__add_btn {
    background-color: $light-blue-color;
  }

  &__container {
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    overflow-x: auto;

    @media screen and (max-width: 1440px) {
      padding: 10px;
      width: 100%;
    }
  }

  &__headers {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1440px) {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }

  &__header_item {
    padding: 0 20px;
    // min-width: 156px;
    width: 20%;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    border-right: 2px solid $date-color;

    &:last-child,
    &:nth-last-child(-n + 2) {
      border-right: 0;
    }
    &:last-child {
      width: 110px;
    }

    @media screen and (max-width: 1440px) {
      font-size: 10px;
      min-width: 120px;
      width: 120px;
      border-right: 0;
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
  &__footer {
    display: flex;
    align-self: flex-end;
    column-gap: 8px;
  }
}
