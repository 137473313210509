@import "../../../../../styles/index.scss";

.LeadHistory__border {
  border-top: 2px solid #668fbd;
  padding-top: 10px;
}

.LeadHistory {
  margin-bottom: 20px;
}

.bold_text {
  font-weight: 600;
}

.detailCardHistoryStatuses,
.LeadHistory {
  margin-bottom: 20px;
  height: 200px;
  overflow: auto;

  &__block {
    margin: 0 0 10px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  &__date {
    align-self: center;
    display: inline-block;
    padding: 6px 12px;
    margin: 0 0 10px 0;
    font-size: 10px;
    color: $status-date-color;
    background-color: $grey-color;
    text-align: center;
    border-radius: 5px;
  }

  &__status {
    position: relative;
    align-self: center;
    margin: 0 0 15px 0;
    padding: 15px 13px;
    text-align: center;
    background: linear-gradient(180deg, #c8e1ff -38.93%, #f1f7ff 75.14%), #fff;
    border-radius: 5px;
    max-width: 80%;
  }

  &__reminder {
    width: 100%;
    align-self: flex-start;
    margin: 0 0 10px 0;
    padding: 7px 10px;
    text-align: start;
    background: linear-gradient(180deg, #c8e1ff -38.93%, #f1f7ff 75.14%), #fff;
    border-radius: 5px;

    &_content {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &_date {
      color: #818080;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__message {
    margin-bottom: 15px;
    &_header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      &_controle {
        margin-left: auto;
        margin-right: 5px;
        display: flex;
        align-items: center;
        gap: 7px;
      }
    }

    &_headerText {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &_author {
      color: #000;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &_date {
      color: #818080;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &_text {
      padding: 12px;
      min-height: 50px;
      border-radius: 0px 10px 10px 10px;
      background: linear-gradient(180deg, #c8e1ff -38.93%, #f1f7ff 75.14%), #fff;
      color: #000;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__time {
    position: absolute;
    right: 5px;
    bottom: 2px;
    font-size: 10px;
    font-weight: 400;
    color: #818080;
  }
}

.iconBtn {
  border: none;
  padding: 0;
  margin: 0;
}

.saveIcon svg {
  width: 20px;
  height: 20px;
  fill: #ced1d4;
}
