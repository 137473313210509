@import "../../styles/index.scss";

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  &__nextItem {
    transform: rotate(180deg);
  }

  &__pageItem {
    display: flex;
    border-radius: 4px;
    padding: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: $black-color;
    opacity: 0.5;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: $light-grey-color;
      transition: background-color 0.3s;
    }

    svg {
      transform: scale(1.4);
      path {
        fill: $black-color;
      }
    }
    &_disabled {
      opacity: 0.1;
    }

    &_isActive {
      opacity: 1;
      &:hover {
        background-color: transparent;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: $black-color;
  }
}
