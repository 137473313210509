@import "../../../styles/variables.scss";
table,
th,
td {
  border: 1px solid $grey-color;
  border-collapse: collapse;
}
.trainingStatisticWithTrainer {
  width: 100%;
  &__block {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }

  &__block_date {
    display: flex;
    align-items: center;
  }

  &__block_picker {
    margin: 0 10px 0 0;
  }

  &__block_btn {
    padding: 0;
    background-color: $light-blue-color;
  }

  &__btn {
    background: $light-blue-color;
  }

  &__table_wrapper {
    overflow-y: scroll;
    max-height: 750px;
    padding: 5px;
    margin-bottom: 20px;
  }

  &__table_head {
    background: $table_header_color;
    th {
      position: sticky;
      top: 0;
      z-index: 999;
      padding: 10px;
      font-size: 12px;
      background: $table_header_color;
    }
  }

  &__table_body {
    td {
      min-width: 85px;
      max-width: 150px;
      padding: 10px;
      font-size: 12px;
    }
  }

  &__table_body_empty {
    width: 100%;
    text-align: center;
    font-weight: 600;
  }

  &__table_rows_higlight {
    background-color: $table_row_highlight;
  }

  &__table_head_active {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      outline: 1px solid #000;
    }
  }

  #projects__table_rows_higlight_department {
    text-align: center;
  }

  #projects__table_rows_first {
    text-align: right;
    padding: 10px 10px 10px 40px;
  }
}

.table__text {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%; /* 15.6px */

  &_int {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
  }
}
