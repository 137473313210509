@import "../../styles/index.scss";

.not_found_container {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.not_found {
  font-size: 14px;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    font-size: 8px;
  }
}
