@import "../../styles/index.scss";

.gallery {
  width: 100%;
  height: 100%;
  background: $white-color;
  padding: 15px;
  border-radius: 12px;
  position: relative;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100px calc(100% - 100px);
  min-width: 320px;

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    background: $white-color;
    &_public {
      background-color: transparent;
    }
  }

  &__header_block {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }

  &__header_block_btn_wrapper {
    display: flex;
    align-items: center;
    span {
      margin: 0 10px 0 0;
    }
  }

  &__container {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: calc(100% - 50px) 50px;
    grid-template-columns: 100%;
  }

  &__public {
    box-shadow: none;
    background-color: transparent;
  }
}

.gallery__header_block_switch {
  display: flex;
  align-items: center;
  gap: 8px;
}

.gallery__header_block_switch_text {
  font-size: 12px;
  color: #00000093;
}

.gallery__header_block_title {
  display: flex;
  align-items: center;
  gap: 15px;
}

.gallery__header_block_inactive_title {
  font-size: 18px;
  font-weight: 800;
  line-height: 21.09px;
  color: #0000004d;
}
