@import "../../../../styles/index.scss";
@import "../../../../styles/variables.scss";

.dealCardCreate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 12px;
  margin-bottom: 20px;

  &__goToClient {
    &_btn {
      background: transparent;
      width: fit-content;
      color: #165397;

      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }

  &__wrapper {
    position: relative;
    padding: 0px 20px 20px 20px;
  }

  &__saperator {
    position: absolute;
    top: 105px;
    left: 50px;
    color: $date-color;
  }

  &__input {
    margin: 14px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    gap: 5px;
    &:first-child {
      margin: 14px 0 40px 0;
    }
    &_warn {
      z-index: 1;
      top: -5px;
      position: absolute;
      right: -10px;
      color: $warn-color;
    }
  }

  &__checkbox {
    margin: 0 10px 0 0;
    span {
      position: relative;
      top: auto;
      right: auto;
    }
  }

  &__input_phone {
    border-width: 1px;
    border-style: solid;
    border-color: $scrollbar-color;
    padding: 7px 11px;
    border-radius: 6px;
    // transition: all 0.2s;
    // &:hover {
    //   border-color: $login-reset-color;
    //   transition: all 0.2s;
    // }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      width: 100%;
      text-align: left;
      margin: 0 0 20px 0;
      color: $date-color;
      font-size: 12px;
      span {
        color: $warn-color;
      }
    }
  }

  &__modal_header {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-color;
    border-radius: 5px 5px 0 0;
  }

  &__modal_close {
    padding: 0;
    border: none;
  }
}

.phone__input {
  width: 100%;
}

.obligatoryFieldCaption {
  font-size: 10px;
  color: red;
  opacity: 0.5;
}
