@import "../../../../styles/index.scss";

.dealCardDetail {
  margin: 10px 0;

  &__projectName {
    &_modal {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 50px;
    }
    &_input {
      margin: 0 0 30px 0;
    }
  }

  &__projectName_wrapper {
    padding: 20px;
  }

  &__projectName_title {
    padding: 10px 0 20px 0;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }

  &__projectName_footer {
    margin: 30px 0 0 0;
    display: flex;
    justify-content: flex-end;
  }

  &__projectName_footer_btn {
    background-color: $light-blue-color;
  }

  &__header {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $grey-color;
    border-radius: 6px 6px 0 0;
    p {
      font-weight: 600;
    }
    svg path {
      fill: $scroll-arrow-color;
    }
    &_close {
      padding: 0;
      width: 25px;
      border: none;
      height: auto;
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.7;
        transition: opacity 0.3s;
      }
    }
  }

  &__block {
    padding: 0px 15px;
  }

  &__createdBy {
    padding: 20px 0;
    span {
      display: inline-block;
      margin: 0 10px 0 0;
      font-size: 10px;
      color: $date-color;
    }
  }

  &__status_title {
    margin: 0 10px 0 0;
    font-weight: 600;
  }

  &__inputs {
    margin: 20px 0;
    display: flex;
    border-radius: 5px;
    display: flex;
    gap: 50px;
  }

  &__inputs_row {
    width: calc(50% - 25px);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__input_block {
    display: flex;
  }

  &__input {
    margin: 0 0 0 10px;
    width: 100%;
    background: none;
    &::placeholder {
      color: $scrollbar-color;
    }
  }

  &__input_edit_button {
    padding: 0;
    width: 25px;
    background: none;
    box-shadow: none;
    border: none;
    &:hover {
      background-color: none;
    }
  }

  &__send_button {
    padding: 0;
    width: 25px;
    background: none;
    box-shadow: none;
    border: none;
    &:hover {
      background-color: none;
    }
  }

  &__input_edit_icon {
    width: 24px;
    height: 40px;
  }

  &__input_close_icon {
    width: 24px;
    height: 14px;
    path {
      fill: $date-color;
    }
  }

  &__reminder {
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 0 20px 0;
  }

  &__reminder_button {
    padding: 0;
    border: none;
  }

  &__button_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }

  &__button {
    width: 49%;
    max-width: 100%;
    background-color: $light-blue-color;
  }

  &__comment {
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    border: 1px solid $date-color;
    border-radius: 5px;
    span {
      font-weight: 600;
    }
    textarea {
      border: 1px solid $date-color;
    }

    &_textBlock {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
    }
  }

  &__submit {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  &__gallery_header {
    margin: 0 0 10px 0;
    display: flex;
    justify-content: flex-end;
  }

  &__gallery_header_close {
    border: none;
  }

  &__modal_wrapper {
    padding: 20px;
  }

  &__modal_header {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-color;
    border-radius: 5px 5px 0 0;
  }

  &__modal_close {
    padding: 0;
    border: none;
  }

  &__projectName_modal {
    padding: 0;
  }

  &__projectName_title {
    display: flex;
    justify-content: center;
    p {
      margin: 0;
      font-weight: 600;
    }
  }

  &__modal_header {
    padding: 2px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-color;
    border-radius: 5px 5px 0 0;
  }

  &__modal_close {
    padding: 0;
    border: none;
  }

  &__projectName_confirm {
    display: flex;
    justify-content: flex-end;
  }

  &__sendingmethod {
    display: flex;
    align-items: center;
    gap: 10px;

    &_title {
      white-space: nowrap;
    }
  }
}

.dealCardDetail__modal_title {
  padding-left: 5px;
}
