@import "../../styles/index.scss";

.Layout {
  width: 100%;

  &__wrapper {
    width: 100%;
    display: flex;
    gap: 35px;
    flex-wrap: wrap;
    @media screen and (min-width: 1170px) {
      flex-wrap: nowrap;
    }
  }

  &__container {
    width: 405px;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    @media screen and (max-width: 1170px) {
      width: 100%;
      height: auto;
    }
  }

  &__gallery {
    width: 100%;
    @media screen and (min-width: 1170px) {
      min-width: 65%;
    }
  }
}
