.NotificationsList {
  max-height: 250px;
  overflow-y: scroll;
  &__item {
    &:first-child {
      border-top: 1px solid #333;
    }
    padding: 10px 12px;
    border-bottom: 1px solid #333;
    cursor: default;

    &:hover {
      background: #e6f7ff;
    }

    &_label {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;

      &:hover {
        font-weight: 600;
      }
    }

    &_circle {
      display: inline-flex;
      margin-right: 8px;
      width: 6px;
      height: 6px;
      background-color: orange;
      border-radius: 50%; /* Делает элемент круглым */
    }
  }
}

.popoverStyle {
  cursor: pointer;
}
