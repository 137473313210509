.custom_control_modal {
  // height: 100%;
  max-height: 900px;
  overflow: hidden;
}

.custom_control_modal .ant-modal-content {
  padding: 0;
  max-height: 950px;
}
