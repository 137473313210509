.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 10px;
}

.ant-tabs-nav {
  overflow: hidden;
  max-width: 100%;
}

.ant-tabs {
  @media screen and (max-width: 1024px) {
    height: fit-content !important;
  }
}

.panorama_custom .pnlm-controls-container {
  right: 10px;
  left: auto;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
}

.panorama_custom .pnlm-controls-container .pnlm-fullscreen-toggle-button {
  position: absolute;
  top: 8px;
  right: 0px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  background-image: url("../../../assets/icons/fullsize.svg");
  background-repeat: repeat-y;
  background-position: 0px 0px;
  border: none;
}
.pnlm-fullscreen-toggle-button.pnlm-sprite.pnlm-fullscreen-toggle-button-inactive.pnlm-controls.pnlm-control.pnlm-fullscreen-toggle-button-active {
  background-image: url("../../../assets/icons/lowsize.svg");
}
.panorama_custom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 380px;
  object-fit: contain;
}

.panorama_custom_fullsize {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.ant-modal .ant-modal-close {
  z-index: 100;
}

.panorama_custom .pnlm-about-msg {
  display: none !important;
}

.panorama_custom .pnlm-load-box {
  display: none !important;
}

.ant-tabs-nav-operations {
  display: none !important;
}
