@import "../../../styles/index.scss";

.link {
  display: flex;
  min-height: 23px;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: $dark-blue-color;
}

.custom_blue {
  background-color: $blue-color;
  color: $white-color;
  &:hover {
    background-color: $dark-blue-color;
  }
}

.custom_orange {
  background-color: $orange-color;
  color: $white-color;
  &:hover {
    background-color: $dark-blue-color;
  }
}

.active.active {
  color: $white-color;
}

.transparent {
  background-color: transparent;
  color: #4073ac;
  &:hover {
    background-color: transparent;
  }
}
