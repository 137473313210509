@import "../../../../../../styles/index.scss";

.collapse {
  &__header {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 10px;
    height: 42px;
    width: 100%;
    z-index: 2;
    position: relative;

    &_project {
      color: $black-color;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      column-gap: 7px;

      &_user_name {
        color: $black-color;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
      }
    }

    &_date,
    &_name {
      color: $status-date-color;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.collapse__header_project_select {
  margin-left: auto;
  margin-right: 27px;
}
