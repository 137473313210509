@import "../../styles/index.scss";

.leadsPage {
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    background: $white-color;
    padding: 15px;
    border-radius: 12px;

    display: grid;
    grid-template-rows: 50px calc(100% - 50px);
    grid-template-columns: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 10px;

    @media screen and (max-width: 991px) {
      width: 90%;
      column-gap: 10px;
      flex-wrap: wrap;
    }

    &_search {
      max-width: 520px;
      min-width: 200px;
      margin: 10px 0;
      padding: 4px 10px;

      @media screen and (max-width: 991px) {
        min-width: 200px;
      }
    }

    &_custom_button {
      &:hover {
        background: #f48300 !important;
      }
      @media screen and (max-width: 1440px) {
        width: auto;
        padding: 8px 14px;
      }
    }
  }

  &__data_container {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 10px 20px 5px;
    overflow: auto;
  }

  &__data_container::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }

  &__data_container::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: 6px;
  }

  &__data_container::-webkit-scrollbar-button:single-button:vertical:decrement,
  &__data_container::-webkit-scrollbar-button:single-button:vertical:increment,
  &__data_container::-webkit-scrollbar-button:single-button:horizontal:decrement,
  &__data_container::-webkit-scrollbar-button:single-button:horizontal:increment {
    width: 12px;
    height: 12px;
  }

  &__data {
    display: flex;
    width: fit-content;
    height: 100%;
    column-gap: 16px;
    justify-content: center;
  }
}
