@import "../../styles/variables.scss";

.publicGallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__block {
    max-width: 1440px;
    width: 99%;
  }
}
