.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  max-width: 100%;
  height: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden;
}

.column__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 7px;
}

.row__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.row__row__container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  border: 1px solid;
}
