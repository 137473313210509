@import "../../../styles/index.scss";

.custom_select_blue {
  min-width: 200px;
  background-color: $blue-color;
  color: $white-color;
  border-radius: 6px;
  &:hover {
    background-color: $white-color;
  }
}

.custom_select_orange {
  min-width: 200px;
  background-color: $orange-color;
  color: $white-color;
  border-radius: 6px;
  &:hover {
    background-color: $white-color;
  }
}

.notfound__wrapper {
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.notfound__text {
  text-align: center;
  font-size: 12px;
}
