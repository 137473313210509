.MailingModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__label {
    font-size: 12px;
    width: fit-content;
    max-width: 50%;
  }

  &__value {
    font-size: 12px;
    width: fit-content;
    max-width: 50%;
    text-align: right;
  }

  &__fakeselect {
    padding: 5px 10px;
    border: 1px solid #c2c7ce;
    width: 200px;
  }

  &__dropdown {
    position: absolute;
    bottom: 0;
    left: 50%;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }

  &__select {
    &_block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
    }

    &_label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_btns {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &_btn {
      border: none !important;
      padding: 0 5px !important;

      &:hover {
        background-color: beige !important;
      }
    }
  }

  &__footer {
    &_btns {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 15px;
    }
  }

  &__add {
    &_btn {
      border: none;
      padding: 0;
      position: absolute;
      right: 0;
      top: -5px;
    }
  }

  &__qr {
    &_btn {
      border: none;
      padding: 0;
    }
  }
}

.errorColor {
  color: #ff0000;
}

.MailingModal__sampleEditBtn {
  border: none;
  padding: 0;
}
