@import "../../styles/index.scss";

.panorama {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  padding: 20px;
  background: $grey-color;
  row-gap: 10px;

  &__data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    border-radius: 5px;
  }

  &__link {
    border-radius: 5px;
    width: 100px;
  }
}
