@import "../../../styles/index.scss";

.shopsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__search_input {
    min-width: 220px;
    border: 1px solid $date-color;
    border-radius: 14px;
    svg {
      width: 14px;
    }
  }
}
