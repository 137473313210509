@import "../../styles/index.scss";

.modalCustom {
  margin: 10px 0;

  &__header {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $grey-color;
    border-radius: 6px 6px 0 0;
    p {
      color: #000;
      font-size: 15px;
      font-weight: 500;
    }
    svg path {
      fill: $scroll-arrow-color;
    }
    &_close {
      padding: 0;
      width: 25px;
      border: none;
      height: auto;
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.7;
        transition: opacity 0.3s;
      }
    }
  }

  &__block {
    padding: 0px 15px;
  }
}
