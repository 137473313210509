@import "../../../styles/index.scss";

.gallery_filters {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-template-rows: auto;
  column-gap: 15px;

  @media screen and (max-width: 1024px) {
    gap: 20px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: row;
  }

  &__select {
    margin-bottom: 0;
  }

  &__selects_container {
    display: block;
    height: fit-content;
    overflow-y: auto;
    width: 100%;

    &__types {
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0 0 15px 0;
    }
  }

  &__search {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    min-width: 190px;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  &__custom_button {
    width: 200px;
    @media screen and (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
    }
  }
}

.gallery_filters__search_bar {
  width: 200px;
}

.gallery_search__name {
  font-size: 12px;
  font-weight: 600;
}

.gallery_search__number {
  font-size: 10px;
  font-weight: 600;
}

.gallery_filters__search_dropdown {
  position: absolute;
  right: 25px;
  top: 20px;
  margin-left: auto;
}
