@import "../../../styles/variables.scss";

.moderationStatus {
  color: $dark-grey-color;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  &__onModeration {
    color: $warn_color;
  }
}
