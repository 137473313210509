@import "../../../styles/variables.scss";
table,
th,
td {
  border: 1px solid $grey-color;
  border-collapse: collapse;
}
.clients {
  width: 100%;
  &__block {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  &__btn {
    margin: 0 0 0 10px;
    background: $light-blue-color;
  }

  &__header_search {
    margin: 20px 0;
    max-width: 266px;
  }

  &__table_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__table_head {
    background: $table_header_color;
    th {
      position: sticky;
      width: 160px;
      top: -1px;
      padding: 10px;
      font-size: 12px;
      background: $table_header_color;
    }
  }

  &__table_fullname {
    text-decoration: underline;
    cursor: pointer;
  }

  &__table_body {
    td {
      width: 180px;
      padding: 10px;
    }
    tr {
      &:hover {
        background-color: $table_row_highlight;
      }
    }
  }

  &__table_head_active {
    cursor: pointer;
    text-decoration: underline;
  }

  &__table_head_hint {
    position: absolute;
    top: 4px;
    left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    color: $white-color;
    font-size: 8px;
    background-color: $orange-color;
  }

  &__pagination {
    padding: 10px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ul li a {
      color: $black-color;
    }
  }
}
