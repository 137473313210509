@import "../../../styles/variables.scss";

.publicGalleryHeader {
  padding: 24px 60px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // justify-content: space-between;
  justify-content: center;
  background-color: $white-color;
  box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, 0.3);
  @media screen and (max-width: 768px) {
    padding: 24px 26px;
  }

  @media screen and (max-width: 576px) {
    padding: 12px 8px;
  }

  @media screen and (max-width: 1024px) {
    justify-content: space-between;
  }

  &__title {
    text-align: center;
    font-size: 28px;
    display: block;
    margin: 0 20px;
    @media screen and (max-width: 1024px) {
      font-size: 16px;
      display: block;
    }
    @media screen and (max-width: 853px) {
      display: none;
    }
  }

  &__title_mobile {
    width: 100%;
    text-align: center;
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  &__logo {
    font-size: auto;
    color: #217baa;
    width: auto;
    @media screen and (max-width: 768px) {
      width: 42%;
      margin: 0 0 24px 0;
      font-size: 12px;
    }
  }

  &__contacts {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: flex-end;
    font-size: auto;
    @media screen and (max-width: 768px) {
      width: 58%;
      margin: 0 0 24px 0;
      font-size: 8px;
    }
    &_city {
      display: flex;
      align-items: center;
      svg {
        width: 15px;
      }
    }
  }
}
