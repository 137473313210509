.ant-picker-dropdown .ant-picker-header-view {
  display: flex;
  width: 100%;
}

.ant-picker-dropdown .ant-picker-header > * {
  flex: auto;
}

.ant-table-wrapper .ant-table-cell,
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td,
.ant-table-wrapper tfoot > tr > th,
.ant-table-wrapper tfoot > tr > td {
  padding: 8px 10px;
}
