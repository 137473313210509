@import "../../../styles/variables.scss";

.galleryProjectItems {
  width: 90%;
  padding: 20px 0;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }

  &__item {
    padding: 0 10px;
    text-align: left;
  }

  &__name {
    font-size: 16px;
    font-weight: 400;
    word-wrap: break-word;
    max-width: 180px;
  }
  &__collection {
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    max-width: 180px;
  }
  &__price {
    font-size: 12px;
    font-weight: 400;
    color: $price-color;
    word-wrap: break-word;
    max-width: 180px;
  }

  .imageBackground {
    width: 160px;
    height: 160px;
    background-color: #dcdce6;
  }
}
