@import "../../styles/index.scss";

.profile_settings {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 335px;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: $white-color;

  &__container {
    padding: 15px 22px 20px;
  }
}
