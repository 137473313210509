@import "../../styles/index.scss";

.controlPanel {
  &__container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 150px calc(100% - 150px);

    height: 100%;
    border-right: 1px solid #0000001a;
    padding-right: 10px;
  }

  &__title {
    &_wrapper {
      max-width: 100%;
    }
  }

  &__buttons_container {
    position: relative;
    display: block;
    overflow: auto;
    padding-bottom: 10px;

    &__buttons_list {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      padding-right: 20px;
    }
  }

  &__modal_header {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-color;
    border-radius: 5px 5px 0 0;
  }

  &__modal_close {
    padding: 0;
    border: none;
  }
}

.controlCard {
  &__wrapper {
    color: #87879e;
    position: relative;
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 12px 8px 12px 8px;
    width: 100%;
    height: 48px;
    background: transparent;
    border: 1px solid #0000001a;
    transition: filter 0.3s linear;
    -webkit-transition: filter 0.3s linear;
    -moz-transition: filter 0.3s linear;
    -ms-transition: filter 0.3s linear;
    -o-transition: filter 0.3s linear;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;

    &:hover {
      filter: brightness(0.9);
      -webkit-filter: brightness(0.9);
      color: $white-color;
      background: #18202b;
    }

    @media screen and (max-width: 1440px) {
      padding: 10px;
    }
  }

  &__current_route {
    filter: brightness(0.9);
    -webkit-filter: brightness(0.9);
    color: $white-color;
    background: #18202b;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-align: left;

    @media screen and (max-width: 1440px) {
      font-size: 14px;
    }
  }

  &__description {
    font-weight: 400;
    line-height: normal;
    text-align: start;
    color: $date-color;
    font-size: 12px;
  }

  &__count {
    position: absolute;
    right: 13px;
    top: 13px;
    color: $orange-color;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }
}

.headerMenu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  background: $header-color;

  @media screen and (max-width: 1440px) {
    padding: 5px 10px;
  }

  &__button {
    padding: 0;
    width: 25px;
    background: none;
    box-shadow: none;
    border: none;
    &:hover {
      background-color: none;
    }
  }

  &__alert_count {
    position: absolute;
    top: -3px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 9px;
    color: $white-color;
    border-radius: 50%;
    background-color: $orange-color;
    border: 1px solid $white-color;
  }

  &__navigation {
    column-gap: 14px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1440px) {
      column-gap: 8px;
    }

    .custom_button {
      font-size: 16px;
      font-weight: 700;
      background-color: transparent;
      transition: color 0.3s;
      padding: 5px;
      color: $header-button-color;
      &:hover {
        color: $white-color;
        transition: color 0.3s;
        background-color: transparent;
      }

      @media screen and (max-width: 1440px) {
        font-size: 14px;
        padding: 4px;
      }
    }
  }

  &__notification_block {
    margin-right: 15px;
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}

.controlPanelHeaderContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 2fr;

  .headerName {
    display: flex;
    align-items: end;
    justify-content: flex-start;
    gap: 10px;
  }
}
