@import "../../../../styles/index.scss";

.stuffCard_detail {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  &__header {
    &_title {
      color: $black-color;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      padding: 15px;
      background: $grey-color;
      border-radius: 8px 8px 0 0;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 0 25px 15px;

    &_data {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &_title {
      color: $black-color;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }

    &_phone {
      padding: 7px 8px;
      border-radius: 5px;
      border: 1px solid $scrollbar-color;
      background: $white-color;

      &:hover {
        border-color: $login-reset-color;
        border-inline-end-width: 1px;
      }

      &:focus {
        border-color: $login-reset-color;
        border-inline-end-width: 1px;
      }
    }

    &_data_label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_psw_btn {
      height: 24px;
      background-color: $light-blue-color;
    }
  }

  &__footer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;

    &_custom_button {
      &_block {
        background: #c60000;
      }
      &_unblock {
        background: green;
      }
    }
  }
}
