@import "../../styles/variables.scss";

.organization__tab {
  padding: 0 20px;
  display: flex;
  align-items: center;

  svg {
    margin: 0 10px 0 0;
  }
  span {
    color: $tabs-text-color;
  }
}

.organization__tabs {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: $white-color;
  padding: 15px;
  border-radius: 12px;
}
