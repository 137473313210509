.filtersSettings {
  display: flex;
  width: 100%;
  height: 700px;
}

.filtersSettings__left {
  width: 35%;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  border-right: 1px solid #00000040;
}

.filtersSettings__right {
  width: 65%;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
}

.filtersList {
  height: 700px;
}

.filtersList__title {
  font-size: 14px;
  line-height: 18px;
  color: #939393;
  text-align: center;
  border-bottom: 1px solid #acb7c5;
  padding-bottom: 5px;
  margin-bottom: 25px;
}

.filtersList__list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow-y: auto;
  padding-top: 5px;
  padding-right: 20px;
  margin-right: 5px;
  height: 620px;
}

.filtersList__item {
  padding: 0;
  border: none;

  & * {
    color: #000;
  }
}

.filtersList__item_block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #acb7c5;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 3px 10px;
}

.filtersList__item_delete_btn {
  padding: 0;
  border: none;
  cursor: pointer;
}

.filtersList__item_save_btn {
  margin-bottom: 10px;
  margin-left: auto;
}

.filterDetailed {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
  overflow: scroll;
}

.filtersSettings__left_addbtn {
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
}

.filtersSettings__empty {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #939393;
}

.filtersList__item_name {
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
