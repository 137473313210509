@import "../../styles/variables.scss";

.blanks {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background: $white-color;
  border-radius: 12px;
}
