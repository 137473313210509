@import "../../../../styles/index.scss";

.ant-modal-footer {
  padding: 0 17px;
  display: flex;
  justify-content: end;
}

.ant-modal-content .ant-modal-header {
  margin: 20px 0;
}

.ant-modal-header .ant-modal-title {
  font-weight: 400;
  font-size: 20px;
  padding: 10px;
  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
}

.ant-select-dropdown {
  z-index: 999999;
}

#custom_deal_create_styles .ant-input:hover {
  border-color: #d9d9d9;
}

#custom_deal_create_styles .ant-input:focus,
#custom_deal_create_styles .ant-input-focused {
  border-color: #d9d9d9;
  box-shadow: none;
}

#custom_deal_create_styles .ant-select-selector {
  border-color: #d9d9d9;
  box-shadow: none;
}

.ant-select-selection-item-content {
  white-space: normal;
  // height: 100px;
}

.ant-select-dropdown .ant-select-item-option-content {
  white-space: normal !important;
}
