@import "../../styles/index.scss";

.ant-input-affix-wrapper {
  padding: 4px 10px;
}

.ant-input-affix-wrapper > input.ant-input::placeholder {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #d9d9d9;
}

.dealsPage__header_sort .ant-select-selector {
  background-color: #e6e6e6 !important;
}

.dealsPage__header_sort .ant-select-selection-item {
  color: #5f5f5f !important;
}
