.workstations {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 0 0;
  width: 100%;
}

.EditWorkstation__wrapper {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.EditWorkstation__edit_btn {
  margin: 0 auto;
}
