.public_select_header .ant-select-selector {
  max-width: 200px;
  overflow: hidden;
  border: none !important;
}

.public_select_header .ant-select-selection-item {
  font-size: 8px;
}

@media screen and (min-width: 768px) {
  .public_select_header .ant-select-selection-item {
    font-size: 14px;
  }
}
