.AddPhoneModal {
  display: flex;
  flex-direction: column;
  gap: 7px;
  &__select {
    &_block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 7px;
    }
  }

  &__footer {
    &_btns {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 15px;
    }
  }

  &__add {
    &_btn {
      border: none;
      padding: 0;
      position: absolute;
      right: 0;
      top: -5px;
    }
  }
  &__errortext {
    color: #ff0000;
  }
}
