@import "../../../styles/variables.scss";

.controlPanelPopUp {
  &__header {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-color;
    border-radius: 5px 5px 0 0;
  }

  &__wrapper {
    padding: 10px;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
  }

  &__close {
    padding: 0;
    border: none;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
      transition: opacity 0.3s;
    }
  }
}
