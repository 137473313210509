.dealCardReminderPopConfirm .ant-popconfirm-description {
  max-width: 200px;
  word-break: break-all;
}

.dealCardReminderPopConfirm .ant-popconfirm-buttons {
  display: none;
}

.ant-picker-calendar .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: none;
}

.custom-date-cell {
  position: relative;
  height: 100%;
}

.custom-date-cell .date {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-date-cell.highlighted::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff8900;
  border-radius: 20px;
  opacity: 0.5;
  z-index: -1;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
