@import "../../../styles/index.scss";
@import "../../../styles/variables.scss";

.ant-modal .ant-modal-content .ant-modal-header {
  margin: 0 0 20px;
}

.custom_styles.ant-skeleton {
  width: 100%;

  .ant-skeleton-image {
    width: 100%;
    height: 100%;
    background: transparent;
  }
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  border: 1px solid $blue-color;
}

.ant-table-row {
  cursor: pointer !important;
}
