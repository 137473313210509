.EditLead {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.EditLead__inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.EditLead__descr {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.72px;
  text-align: left;
  color: #464b51;
}

.EditLead__status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.EditLead__input_block {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 85%;
}

.EditLead__label {
  display: inline-block;
  min-width: 110px;
  font-weight: 500;
}

.EditLead__tag {
  width: fit-content;
  display: block;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  width: 100%;
  margin: 0;
  min-height: 24px;
}

.EditLead__submitBlock {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.EditLead__submit {
}

.EditLead__client {
  background: transparent;
  width: fit-content;
  color: #165397;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  -moz-text-decoration-line: underline;
}

.EditLead__reminder_button {
  padding: 0;
  border: none;
}
