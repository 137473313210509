@import "../../../styles/index.scss";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__content {
    padding: 50px;
    color: $header-color;
  }
}
