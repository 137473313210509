.custom-slider .slick-next {
  transform: rotate(-90deg);
  right: -16px;
}
.custom-slider .slick-prev {
  transform: rotate(90deg);
  left: -16px;
}

.custom-slider .slick-disabled {
  opacity: 0.4;
}

.slick-list {
  margin-right: 5px;
  margin-left: 5px;
}
