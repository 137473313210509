@import "../../../styles/index.scss";
@import "../../../styles/variables.scss";

.gallery_card_detailed {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;

  &_copy {
    background: transparent;
    padding: 10px;
    margin: 0;
    margin-bottom: 5px;
    border: 1px solid $dark-grey-color;
    width: fit-content;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  &_qr {
    margin-bottom: 15px;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
  }
  &__container {
    width: 100%;
    &_preview {
      width: 100%;
      height: 100%;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  &__label {
    width: 120px;
    height: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    @media screen and (max-width: 1024px) {
      // width: 100px;
    }
  }
  &__qr {
    bottom: 20px;
    right: 9px;
    position: absolute;
  }

  &__code {
    bottom: 80px;
    right: 9px;
    position: absolute;
  }

  &__qr_btn {
    padding: 0;
    border: none;
  }

  &__code_btn {
    width: 50px;
    height: 50px;
    padding: 0;
    border: none;
    background-color: #2f2f2f !important;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  &__qr_download {
    margin: 20px 0 0 0;
    padding: 0;
    border: none;
  }
  &__modal_qr_header {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-color;
    border-radius: 5px 5px 0 0;
  }
  &__modal_qr_close {
    padding: 0;
    border: none;
  }
  &__qr_container {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__warning {
    display: inline-block;
    margin: 0 0 20px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    @media screen and (max-width: 1024px) {
      margin: 0 0 10px 0;
      font-size: 12px;
    }
  }

  &__error {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $warn_color;
    text-align: center;
  }

  &__ios_btn {
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
      position: absolute;
      z-index: 999;
      top: 10px;
      right: 10px;
    }
  }

  &__button_hidden {
    margin: 0 10px 0 0;
    background-color: $hide_btn;
    color: $black-color;
    min-width: fit-content;
    max-width: calc((100% / 2) - 20px);
  }

  &__button_show {
    margin: 0 10px 0 0;
    background-color: $dark-grey-color;
    min-width: fit-content;
    max-width: calc((100% / 2) - 20px);
  }

  &__panorama_wrapper {
    display: flex;
    justify-content: center;
  }
}

.gallery_card_detailed__svg {
  fill: #fff;
}

.gallery_card_detailed__buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 50px;
}

.gallery_card_detailed_info {
  color: #3f3f3f;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
}

.gallery__card_title_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gallery__card_number {
  font-size: 14px;
  font-weight: 600;
  color: #0000005a;
}

.previewImageStyle {
  width: 100%;
  height: 95px;
  object-fit: cover;
}

.selectedImageStyle {
  object-fit: contain;
}
