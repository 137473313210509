.InputWithViewMode__viewValue_block {
  display: flex;
  align-items: center;
  gap: 15px;
}

.InputWithViewMode__edit_btn,
.InputWithViewMode__close_btn,
.InputWithViewMode__submit_btn {
  padding: 0;
  border: none;
}

.InputWithViewMode__input_block {
  position: relative;
  display: flex;
  width: 100%;
}

.InputWithViewMode__close_btn,
.InputWithViewMode__submit_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.InputWithViewMode__close_btn {
  right: 2px;
}

.InputWithViewMode__submit_btn {
  right: 27px;
}

.InputWithViewMode__input {
  width: 100%;
  padding-right: 50px;
}

.InputWithViewMode__viewValue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.InputWithViewMode__viewValue_bold {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 23.44px;
  text-align: left;
}

.InputWithViewMode {
}
