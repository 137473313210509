@import "../../../../styles/variables.scss";

.workstationAdd {
  &__modal_header {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $grey-color;
    border-radius: 5px 5px 0 0;
  }
  &__modal_btn {
    background-color: $light-blue-color;
  }

  &__modal_close {
    padding: 0;
    border: none;
  }

  &__body {
    padding: 20px 12px 20px 12px;
  }

  &__block {
    margin: 0 0 20px 0;
  }

  &__block_btn {
    display: flex;
    justify-content: center;
  }

  &__add_btn {
    background-color: $light-blue-color;
  }
}
