@import "../../styles/index.scss";

.resetLoginPage {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 450px;
    height: 100%;
  }

  &__title {
    max-width: 275px;
    margin-bottom: 15px;
    text-align: center;
    color: $black-color;
    font-size: 18px;
    font-weight: 400;
  }

  &__phone {
    padding: 7px 8px;
    border-radius: 5px;
    border: 1px solid $scrollbar-color;
    background: $white-color;

    &:hover {
      border-color: $login-reset-color;
      border-inline-end-width: 1px;
    }

    &:focus {
      border-color: $login-reset-color;
      border-inline-end-width: 1px;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px 30px;
    width: 70%;
  }

  &__hint {
    display: inline-block;
    margin: 15px 0;
    font-size: 10px;
    text-align: center;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 191px;
  }

  &__submit {
    max-width: 191px;
  }

  &__passwordReset {
    max-width: 191px;
  }

  &__typeBtn {
    color: $login-type-color;
  }
}
