@import "../../styles/index.scss";

.container {
  display: block;
  position: relative;
  width: 100%;
  height: 100vh;

  background: #08131d;
}

.children {
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 315px calc(100% - 315px);
  grid-template-rows: 100%;
  padding: 10px 15px 5px 15px;

  &_horizontal_scroll {
    overflow-x: hidden;
  }
}

::-webkit-scrollbar {
  height: 8px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  width: 20px;
  background-color: $scrollbar-color;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 0px 20px 0px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 8px solid $scroll-arrow-color;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 0 0px 20px 0px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 8px solid $scroll-arrow-color;
}

::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border-width: 20px 0px 0 0px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-right: 8px solid $scroll-arrow-color;
}

::-webkit-scrollbar-button:single-button:horizontal:increment {
  border-width: 20px 0px 0 0px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 8px solid $scroll-arrow-color;
}
